import React, { Component } from 'react';
import {
    Container, Row, Col
} from 'reactstrap';
import styles from '../../../components/Vendor/Apple/UploadDocs/UploadDoc.module.css';
import classNames from 'classnames';
import { UploadDocs } from '../../../components/Vendor/Apple/UploadDocs/UploadDocs';
import ClaimSummary from '../../../components/Vendor/Apple/IntakeLoss/ClaimSummary';
//import NeedHelp from '../../../components/Vendor/Apple/IntakeLoss/NeedHelp';
import { callAdobe } from '../../../helpers';
import { CombinedLangContractProvider } from '../../../components/contexts/ProviderLangContractContext';

let mrAuto = "mr-auto";
let formBody = styles.formBody;

export class UploadDocsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusType: this.props.statusType,
            contractNumber: "",  // case number
            client: this.props.clientId,
            claimId: this.props.claimId,
            claimNumber: this.props.claimNumber,
        }
        this.viewConfirmationScreen = this.viewConfirmationScreen.bind(this);
    }

    componentDidMount() {

        const currentPathname = this.props.history?.location?.pathname;

        if (currentPathname?.toLowerCase().includes('/vendor/apple/uploaddocs')) {
            window.history.pushState(this.props.history?.location, document.title, window.location.href);
            window.addEventListener('popstate', this.viewConfirmationScreen);
        }

        // FOR ADOBE ANALYTICS
        callAdobe({
            pageName: 'Upload Documents',
            pagePath: '/vendor/apple/uploaddocs',
            caseId: this.props.history?.location?.state?.entitlementDetails?.caseNumber,
            claimStatus: this.props.history?.location?.state?.entitlementDetails.contract?.statusModel?.statusName,
            country: this.context.languageContext?.country,
            language: this.context.languageContext?.language,
            currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
        })
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.viewConfirmationScreen);
    }

    viewConfirmationScreen = () => {

        const currentState = this.props.history?.location?.state;
        const emailAddress = currentState?.entitlementDetails?.email || currentState?.entitlementDetails?.party?.partyEmailModel[0]?.address;

        this.props.history.push('/vendor/apple/confirmation', {
            isFraud: true,
            statusType: "docsPending",
            caseNumber: currentState?.caseNumber || '',
            ccNumber: currentState?.ccNumber || '',
            ccType: currentState?.ccType || '',
            claimNumber: currentState?.claimNumber || '',
            claimId: currentState?.claimId || '',
            entitlementDetails: currentState?.entitlementDetails || {},
            claimEmail: emailAddress || ''
        });
    }

    render() {

        return(

            <Container className="contentWrapper">
                <Row>
                    <Col lg="7" className={classNames(formBody, mrAuto)}>
                        <UploadDocs history={this.props.history}></UploadDocs>
              
                    </Col>
                    <ClaimSummary step={4} entitlementDetails={typeof this.props.location.state === "undefined" ? new Object() : this.props.location.state.entitlementDetails} ccNumber={this.props.location.state?.ccNumber} ccType={this.props.location.state?.ccType} />
                    {/*<NeedHelp/>*/}
                </Row>
            </Container>
        );
    }
}

UploadDocsContainer.contextType = CombinedLangContractProvider;